import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, AppBar, Toolbar, IconButton, Stack } from "@mui/material";
// components
import Iconify from "../../components/Iconify";
import AccountPopover from "./AccountPopover";
import Searchbar from "./Searchbar";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl, loginPath, redirectUrl } from "src/pages/config";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const navigate = useNavigate();
  useEffect(() => {
    axios({
      method: "get",
      url: baseUrl + "/api/getPrivilageUserData",
      withCredentials: true,
    })
      .then((res) => {
        const jsonString = res.data[0].privilages;
        const privObject = JSON.parse(jsonString);

        const trueKeys = Object.keys(privObject).filter(
          (key) => privObject[key]
        );
        const sortedKeys = trueKeys.sort();
        localStorage.setItem("acc", JSON.stringify(sortedKeys));
      })
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          window.location.href = redirectUrl;
        }
      });
  });
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
